import { joinInvite } from "api";
import { checkJoinInvite } from "api";
import RouteLoader from "components/RouteLoader";
import { useGlobalContext } from "context/GlobalContext";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./JoinDomain.scss";

const JoinDomain = () => {
  const navigate = useNavigate();
  const { invite_token } = useParams();
  const { setLoading } = useGlobalContext();
  const [invite, setInvite] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const checkInvite = async () => {
      try {
        setLoading(true);
        const data = await checkJoinInvite(invite_token);
        setInvite(data.data);
        setError(data.message);
      } catch (error) {
        console.error("Failed to fetch design system", error);
      } finally {
        setLoading(false);
      }
    };
    checkInvite();
  }, []);

  const handleJoin = async () => {
    try {
      const { data } = await joinInvite(invite.id);
      if (data) navigate("/dashboard");
    } catch (error) {
      console.error("Failed to join invite", error);
    }
  };
  console.log(error);
  let content;
  if (!invite) {
    content = (
      <div>
        <div className='invitation-container d-flex flex-column align-items-center justify-content-center w-100 h-100vh'>
          <h2 className='invitation-msg plt-heading-2xl'>{error}</h2>
          <a
            href='/login'
            className='cursor-pointer plt-btn plt-btn-lg plt-btn-primary'
          >
            Please login
          </a>
        </div>
      </div>
    );
  } else {
    content = (
      <div>
        <div className='invitation-container d-flex flex-column align-items-center justify-content-center w-100 h-100vh'>
          <h2 className='invitation-msg plt-heading-2xl'>
            You have received to join in {invite?.admin?.companyname}
          </h2>
          <div className='join-btn'>
            <button
              className='cursor-pointer plt-btn plt-btn-lg plt-btn-primary'
              onClick={handleJoin}
            >
              Join {invite?.admin?.companyname}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <RouteLoader>{content}</RouteLoader>
    </>
  );
};

export default JoinDomain;
